



































































import { mapGetters } from 'vuex';
import { Component, Vue } from 'vue-property-decorator';
import Layout from '@/layouts/Layout.vue';
import i18next from 'i18next';
import Localization from '@/services/localization';
import Pagination from '@/components/Pagination/Pagination.vue';
import _ from 'lodash';

@Component({
  components: {
    Layout,
    Pagination
  },
  data() {
    return {
      search: '',
    };
  },
  computed: mapGetters(['tenantProductCatalog'])
})
export default class SettingsProductCatalog extends Localization {
  // private catalog = [{}];
  private mounted() {
    this.$store.dispatch('setSearchTitle', 'All Products');
    this.$store.dispatch('editSearchQuery', {});
  }

  private changeProductVisibility(product: any) {
    if (this.cartedProducts.includes(product._series._id)) {
      this.$notify.error({
        title: i18next.t('settings.catalog.notify.TOGGLETITLE'),
        message: i18next.t('settings.catalog.notify.TOGGLEMSG'),
        duration: 3000,
        position: 'top-left',
      });
    } else {
      product.isFavourite = !product.isFavorite;
      this.$store.dispatch('changeProductVisibility', product);
    }
  }

  private get cartedProducts() {
    return _.chain(this.$store.getters.cartedPreordersCyclopsDisplayList)
            .map('_series._id')
            .uniq()
            .value();
  }

  private get getProductCatalog() {
    const productCatalog = this.$store.getters.tenantProductCatalog.products;
    return _.filter(productCatalog, (k) => ((k as any).available && !(k as any)._series.trial));
  }

  private toggleFavorite(row: any, col: any) {
    row.isFavourite = !row.isFavourite;
    this.getProductCatalog.splice(_.findIndex(this.getProductCatalog, {id: row.id}), 1, row);
    this.$store.dispatch('changeProductVisibility', row)
    .then((res: any) => {
      // this.catalog = this.getProductCatalog();
    })
    .catch((err: any) => {
      console.error('Error changing product visibility', err);
    });
  }

  private nextPage(pageNo: number) {
    this.$store.dispatch('editPage', pageNo++);
  }

  private prevPage(pageNo: number) {
    this.$store.dispatch('editPage', pageNo--);
  }

  private clickPage(pageNo: number) {
    this.$store.dispatch('editPage', pageNo);
  }

  private get totalRecords() {
    return this.$store.getters.getTotalRecords;
  }

  private get productsPerPage() {
    return this.$store.getters.getProductPageSize;
  }
}
