var render = function () {
var _obj, _obj$1;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showPagination),expression:"showPagination"}],class:_vm.$style.pagination,on:{"reset":_vm.resetPage}},[_c('a',{class:[_vm.$style.pagenext, ( _obj = {}, _obj[_vm.$style.disabled] = _vm.showNext, _obj )],on:{"click":_vm.clickNext}},[_vm._m(0)]),_c('div',{class:_vm.$style.pages},_vm._l((_vm.noPages),function(page,index){
var _obj;
return _c('a',{key:index,class:[
        _vm.$style.page,
        ( _obj = {}, _obj[_vm.$style.active] = page === _vm.currentPage, _obj )
        ],on:{"click":function($event){return _vm.clickPage(page)}}},[_c('span',[_vm._v(_vm._s(page))])])}),0),_c('a',{class:[_vm.$style.pageprev, ( _obj$1 = {}, _obj$1[_vm.$style.disabled] = _vm.showPrev, _obj$1 )],on:{"click":_vm.clickPrev}},[_vm._m(1)])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',[_c('i',{staticClass:"icon-chevron-left",attrs:{"aria-hidden":"true"}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',[_c('i',{staticClass:"icon-chevron-right",attrs:{"aria-hidden":"true"}})])}]

export { render, staticRenderFns }