
















import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import Route from 'vue-router';
import Localization from '@/services/localization';

@Component({
})
export default class Pagination extends Localization {

  // data
  @Prop({ default: 0 }) private total!: number;
  @Prop({ default: 0 }) private perPage!: number;
  @Prop({ default: 1 }) private currentPage!: number;
  private totalPages: number = 0;

  // methods
  private mounted() {
    // mounted
  }

  private resetPage() {
    this.currentPage = 1;
  }

  private clickNext() {
    if (this.currentPage === this.totalPages) {
      this.currentPage = this.totalPages;
    } else {
      this.currentPage++;
    }
    this.$emit('next', this.currentPage);
  }

  private clickPrev() {
    if (this.currentPage === 1) {
      this.currentPage = 1;
    } else {
      this.currentPage--;
    }
    this.$emit('prev', this.currentPage);
  }

  private clickPage(page: number) {
    this.currentPage = page;
    this.$emit('page', page);
  }

  // computed
  private get noPages() {
    if (!this.total || !this.perPage ) {
      this.totalPages = 1;
    } else if (this.total < this.perPage) {
      this.totalPages = 1;
    } else {
      this.totalPages = Math.ceil(this.total / this.perPage);
      if (this.totalPages === 0) {
        this.totalPages++;
      }
    }
    // console.log('PAGINATION', this.perPage, this.totalPages, this.total);
    return this.totalPages;
  }

  private get showPagination() {
    return this.totalPages < 2 ? false : true;
  }

  private get showPrev() {
    return this.currentPage === 1 ? false : true;
  }

  private get showNext() {
    return this.currentPage === this.totalPages ? false : true;
  }

}
